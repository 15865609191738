import React, { Fragment, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CountUp from 'react-countup';
import { TbDeviceMobilePlus, TbDeviceMobileDollar, TbDeviceMobileCheck, TbDeviceMobileExclamation } from "react-icons/tb";

const { fetchAllDevicesBetweenDate } = require('../../../api/dashboardCardsApi');

const CardComponent = () => {
    const fortyDaysAgo = new Date();
    fortyDaysAgo.setDate(fortyDaysAgo.getDate() - 40);
    const year = fortyDaysAgo.getFullYear();
    const month = String(fortyDaysAgo.getMonth() + 1).padStart(2, '0');
    const day = String(fortyDaysAgo.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0');
    const currentDay = String(currentDate.getDate()).padStart(2, '0');

    const formatCurrentDate = `${currentYear}-${currentMonth}-${currentDay}`;

    const [installStartDate, setInstallStartDate] = useState(new Date(formattedDate));
    const [installEndDate, setInstallEndDate] = useState(new Date(formatCurrentDate));
    const [dropdownToggleValue, setDropdownToggleValue] = useState("Last 40 Days Analytics");
    const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
    const [allDevicesData, setAllDevicesData] = useState({
        totalDevices: 0,
        activeDevices: 0,
        expiresDevices: 0,
        paidDevices: 0
    });

    const toggleDateDropdown = () => {
        setIsDateDropdownOpen(!isDateDropdownOpen);
    };

    // Fetch all devices function
    const fetchAllDevicesData = async () => {
        try {
            const result = await fetchAllDevicesBetweenDate(installStartDate, installEndDate);
            const data = result.reduce((acc, item) => {
                if (item.totalDevices !== undefined) acc.totalDevices = item.totalDevices;
                if (item.activeDevices !== undefined) acc.activeDevices = item.activeDevices;
                if (item.expiresDevices !== undefined) acc.expiresDevices = item.expiresDevices;
                if (item.paidDevices !== undefined) acc.paidDevices = item.paidDevices;
                return acc;
            }, {});
            setAllDevicesData(data);
        } catch (error) {
            console.error('Error fetching devices:', error);
        }
    };

    useEffect(() => {
        fetchAllDevicesData();
    }, [installStartDate, installEndDate]);

    const formatDate = (date) => {
        return date ? date.toLocaleDateString() : '';
    };

    const handleApplyDateFilter = () => {
        setDropdownToggleValue(`${formatDate(installStartDate)} to ${formatDate(installEndDate)}`);
        setIsDateDropdownOpen(false);
    };

    const handleClearDateFilter = () => {
        setInstallStartDate(new Date(formattedDate));
        setInstallEndDate(new Date(formatCurrentDate));
        setDropdownToggleValue("Last 40 Days Analytics");
        setIsDateDropdownOpen(false);
    };

    return (
        <Fragment>
            <div className='ms-2 pb-2' >
                <div className="custom-dropdow1 mt-2 pt-3 d-flex justify-content-end" style={{marginRight:'10px'}}>
                    <div className={`dropdown input_dropdown ${isDateDropdownOpen ? 'show' : ''} `} >
                        <input
                            className="dropdown-toggle border-0 h-100 ps-2"
                            style={{ fontSize: '16px', fontWeight: '350' }}
                            type="button"
                            onClick={toggleDateDropdown}
                            value={dropdownToggleValue}
                        />
                        <ul className={`dropdown-menu ${isDateDropdownOpen ? 'show' : ''}`} style={{ left: '-32px' }}>
                            <li>
                                <div className="" style={{ marginTop: '2px' }}>
                                    <DatePicker
                                        wrapperClassName="datePicker ms-1 my-2"
                                        selected={installStartDate}
                                        onChange={date => setInstallStartDate(date)}
                                        placeholderText="Start Date"
                                    />
                                    <DatePicker
                                        wrapperClassName="datePicker ms-1 me-2  my-2"
                                        selected={installEndDate}
                                        onChange={date => setInstallEndDate(date)}
                                        placeholderText="End Date"
                                    />
                                    <button className="mx-1 apply  my-2" onClick={handleApplyDateFilter}>Apply</button>
                                    <button className="mx-1 clear my-2" onClick={handleClearDateFilter}>Clear</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div>
                    <div className="topCards mt-2">
                        <div className="cardSubClass" >
                            <div className='d-flex align-items-center'>
                                <div className='icon1Div '>
                                    <TbDeviceMobilePlus className='iconClass' style={{ color: '#007bff' }} />
                                </div>
                                <div className='ms-3'>
                                    <h4 style={{ color: '#4f4c4c' }}><CountUp end={allDevicesData.totalDevices} /></h4>
                                    <p className='mb-1'>Total Devices</p>
                                </div>
                            </div>
                        </div>
                        <div className="cardSubClass" >
                            <div className='d-flex align-items-center'>
                                <div className='icon1Div '>
                                    <TbDeviceMobileCheck className='iconClass' style={{ color: '#007bff' }} />
                                </div>
                                <div className='ms-3'>
                                    <h4 style={{ color: '#4f4c4c' }}><CountUp end={allDevicesData.activeDevices} /></h4>
                                    <p  className='mb-1'>Active Devices</p>
                                </div>
                            </div>
                        </div>
                        <div className="cardSubClass" >
                            <div className='d-flex align-items-center'>
                                <div className='icon1Div'>
                                    <TbDeviceMobileExclamation className='iconClass' style={{ color: '#007bff' }} />
                                </div>
                                <div className='ms-3'>
                                    <h4 style={{ color: '#4f4c4c' }}><CountUp end={allDevicesData.expiresDevices} /></h4>
                                    <p className='mb-1'>Expires Devices</p>
                                </div>
                            </div>
                        </div>
                        <div className="cardSubClass" >
                            <div className='d-flex align-items-center'>
                                <div className='icon1Div'>
                                    <TbDeviceMobileDollar className='iconClass' style={{ color: ' #007bff' }} />
                                </div>
                                <div className='ms-3'>
                                    <h4 style={{ color: '#4f4c4c' }}><CountUp end={allDevicesData.paidDevices} /></h4>
                                    <p className='mb-1'>Paid Devices</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CardComponent;
