import React, { useState } from 'react';
import ModalComponent from './modalComponent';
import SDK from '../../../config';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { fetchCsvData } from '../../../redux/actions';
import Loader from '../../../layouts/loader/Loader';
import { useDispatch } from 'react-redux';

const EmailModal = ({ showModal, setShowModal }) => {
  const [buttonText, setButtonText] = useState("Submit");
  const [email, setEmail] = useState(""); 
  const [emailError, setEmailError] = useState(""); 
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  const handleClose = () => {
    setShowModal(false);
  };

  const handleUpload = async () => {
    if (!email.trim()) {
        setEmailError("Please enter a valid email address");
        return; 
      }
      setLoader(true)
    try {
      const response = await axios.post(SDK.BASE_URL + "/addEmail", { email });
      if (response.status === 200) {
        dispatch(fetchCsvData())
        toast.success('Email is Uploaded');
        setShowModal(false); 
      }
    } 
    catch (error) {
      if (error.response && error.response.data.error === 'Email already exists') {
        toast.info('Email already exists');
      } 
      else {
        toast.error('Failed to upload email');
      }
      console.error("Error uploading email:", error);
    }
    finally{
        setLoader(false)
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if (!value || !/\S+@\S+\.\S+/.test(value)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  return (
    <div>
     <Loader pageLoder={loader} />

    <ModalComponent
      showModal={showModal}
      setShowModal={setShowModal}
      title="Add Email"
      handleClose={handleClose}
      buttonText={buttonText}
      handleAction={handleUpload}
    >
      <div className="container">
        <form className="form w-auto">
          <center>
            <input
              style={{
                width: '100%',
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '8px',
                boxSizing: 'border-box',
              }}
              placeholder='Enter Email'
              type="email"
              className="file-upload-field"
              value={email}
              onChange={handleChange} 
            />
            {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
          </center>
        </form>
      </div>
    </ModalComponent>
    </div>
  );
}

export default EmailModal;
