import React, { useState, useEffect } from 'react';
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Scatter,
    ResponsiveContainer,
} from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import { fecthWeeklyStats } from '../../../../redux/actions.js';

const AllDataInweekChart = () => {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const weeklyStats = useSelector(state => state.data.weeklyStats);

    useEffect(() => {
        setLoader(true);
        dispatch(fecthWeeklyStats())
            .then(() => {
                setLoader(false);
            }).catch(error => {
                console.error('Error fetching payments:', error);
                setLoader(false);
            });
    }, [dispatch]);

    useEffect(() => {
        if (weeklyStats) {
            setData(weeklyStats);
            setLoader(false);
        }
    }, [weeklyStats]);

    return (
        <>
            <ResponsiveContainer width="100%" height={300}>
                <ComposedChart
                    width={500}
                    height={250}
                    data={data}
                    margin={{
                        top: 0,
                        right: 20,
                        bottom: 5,
                        left: -10,
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="dayName" scale="band" />
                    <YAxis />
                    <Tooltip />
                    <Area type="monotone" dataKey="totalDevices" fill="#b3e1f9" stroke="#b3e1f9" />
                    <Bar dataKey="totalActive" barSize={17} fill="#007bff" />
                    <Line type="monotone" dataKey="totalExpires" stroke="#FFBB28" />
                    <Scatter dataKey="totalPaid" fill="#00C49F" />
                </ComposedChart>
            </ResponsiveContainer>

        </>
    );
};

export default AllDataInweekChart;
