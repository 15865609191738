// actions.js
import { setDevices, setPayments, setReadChat, setCsvData, setGetInTouch,setSevenDaysData,setWeeklyStats,setAllStats,setUserServey,setAllUnreadChat,setUnResolveChat,setResolveChat,setPaymentIssues } from './dataSlice';
import SDK from '../config';

export const fetchDevices = () => async (dispatch) => {
  try {
    const response = await fetch(SDK.BASE_URL + `/all-devices`);
    const jsonData = await response.json();
    dispatch(setDevices(jsonData));
  } catch (error) {
    console.error('Error fetching devices:', error);
  }
};


export const fetchPayments = () => async (dispatch) => {
  try {
    const response = await fetch(SDK.BASE_URL + `/id/info/`);
    const jsonData = await response.json();
    dispatch(setPayments(jsonData));
  } catch (error) {
    console.error('Error fetching payments:', error);
  }
};

export const fetchReadChat = () => async (dispatch) => {
  try {
    const response = await fetch(SDK.BASE_URL + `/all-users-chats`);
    const jsonData = await response.json();
    dispatch(setReadChat(jsonData));
  } catch (error) {
    console.error('Error fetching payments:', error);
  }
};

export const fetchUnResolveChat = () => async (dispatch) => {
  try {
    const response = await fetch(SDK.BASE_URL + `/all-unresolved-chats`);
    const jsonData = await response.json();
    dispatch(setUnResolveChat(jsonData));
  } catch (error) {
    console.error('Error fetching payments:', error);
  }
};

export const fetchResolveChat = () => async (dispatch) => {
  try {
    const response = await fetch(SDK.BASE_URL + `/all-resolved-chats`);
    const jsonData = await response.json();
    dispatch(setResolveChat(jsonData));
  } catch (error) {
    console.error('Error fetching payments:', error);
  }
};

export const fetchCsvData = () => async (dispatch) => {
  try {
    const response = await fetch(SDK.BASE_URL + `/getcsvinfo`);
    const jsonData = await response.json();
    dispatch(setCsvData(jsonData));
  } catch (error) {
    console.error('Error fetching payments:', error);
  }
};


export const fetchGetInTouchEmails = () => async (dispatch) => {
  try {
    const response = await fetch(SDK.BASE_URL + `/getallemails`);
    const jsonData = await response.json();
    dispatch(setGetInTouch(jsonData));
  } catch (error) {
    console.error('Error fetching payments:', error);
  }
};

export const fecthUserSurvey = () => async (dispatch) => {
  try {
    const response = await fetch(SDK.BASE_URL + `/user/servey`);
    const jsonData = await response.json();
    dispatch(setUserServey(jsonData));
  } catch (error) {
      console.log(error);
  }
}

export const fecthLastSevenDays = () => async (dispatch) => {
  try {
    const response = await fetch(SDK.BASE_URL + `/last-seven-days`);
    const jsonData = await response.json();
    dispatch(setSevenDaysData(jsonData));
  } catch (error) {
      console.log(error);
  }
}

export const fecthWeeklyStats = () => async (dispatch) => {
  try {
    const response = await fetch(SDK.BASE_URL + `/all-data-last-week`);
    const jsonData = await response.json();
    dispatch(setWeeklyStats(jsonData));
  } catch (error) {
      console.log(error);
  }
}

export const fecthAllStats = () => async (dispatch) => {
  try {
    const response = await fetch(SDK.BASE_URL + `/all-devices-statics`);
    const jsonData = await response.json();
    dispatch(setAllStats(jsonData));
  } catch (error) {
      console.log(error);
  }
}

export const fecthAllUnreadChat = () => async (dispatch) => {
  try {
    const response = await fetch(SDK.BASE_URL + `/all-chatsmessage`);
    const jsonData = await response.json();
    dispatch(setAllUnreadChat(jsonData));
  } catch (error) {
      console.log(error);
  }
}

export const fecthAllPaymentIssues = () => async (dispatch) => {
  try {
    const response = await fetch(SDK.BASE_URL + `/api/getPayment/issues`);
    console.log(response);
    const jsonData = await response.json();
    dispatch(setPaymentIssues(jsonData));
  } catch (error) {
      console.log(error);
  }
}