import React from "react";
import "./loader.scss";
import { Modal, Spinner } from "react-bootstrap";

const Loader = ({pageLoder,style}) => (
  <Modal
  style={{style}}
  show={pageLoder}
  size="sm"
  aria-labelledby="contained-modal-title-vcenter"
  centered
  fullscreen={true}
  className="modal-backdrop"
>
  <Modal.Body style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </Modal.Body>
</Modal>
);
export default Loader;
