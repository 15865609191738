import React, { Fragment } from 'react'
import ActiveDevices from './extraPages/getStaticsDeviceTable'

const Statics = () => {
    return (
        <Fragment>
            <div className='d-flex'>
                <div className='w-50'>
                    <ActiveDevices />
                </div>
            </div>
        </Fragment>
    )
}

export default Statics
