import React from "react";
import {  useNavigate } from "react-router-dom";
import {
  Navbar,
  Collapse,
  Nav,
  NavbarBrand,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
} from "reactstrap";
import user1 from "../assets/images/logos/App Icon.png";
import '../layouts/layoutStyle.css'


const Header = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  
  function logout(){
    localStorage.setItem("token",false);
    navigate('/');
    window.location.reload(true);
  }

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const Handletoggle = () => {
    setIsOpen(!isOpen);
  };
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };
  const hideNavbar= ()=>{
    var sidebarArea = document.getElementById("sidebarArea");
    if (sidebarArea.style.display === "none") {
      sidebarArea.style.display = "block";
    } else {
      sidebarArea.style.display = "none";
    }
  }
  
  return (
    <Navbar style={{background:'#DFDDD3'}} dark expand="md">
      <div className="d-flex  align-items-center ">
        <Button
          className="hide-on-small-and-tablet ms-2"
          style={{background:'#DFDDD3', border:'none'}}
          onClick={() => hideNavbar()}
        >
          <i className="bi bi-list" style={{color:"black"}}></i>
        </Button>
        <Button
          style={{background:'#DFDDD3', border:'none'}}
          color=" ms-2"
          className="d-lg-none btHover"
          onClick={() => showMobilemenu()}
        >
          <i className="bi bi-list" style={{color:"black"}}></i>
        </Button>
        <NavbarBrand href="/" className="d-lg-none"> 
          <h2 style={{margin:"10px",color:"black"}}>Minimis</h2>
        </NavbarBrand>
      </div>
      <div className="hstack gap-2">
        <Button
          style={{background:'#DFDDD3', border:'none'}}
          size="sm"
          className="d-sm-block d-md-none"
          onClick={Handletoggle}
        >
          {isOpen ? (
            <i className="bi bi-x"></i>
          ) : (
            <i className="bi bi-three-dots-vertical"></i>
          )}
        </Button>
      </div>

      <Collapse navbar isOpen={isOpen}>
        <Nav className="me-auto" navbar>      
        </Nav>
        <Dropdown className="me-5 "  isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle className="btHover"  style={{background:'#DFDDD3', border:'none'}}>
            <img
              src={user1}
              alt="profile"
              className="rounded-circle"
              width="30"
            ></img>
          </DropdownToggle>
          <DropdownMenu style={{marginRight:"55px"}} > 
            <DropdownItem onClick={() => logout()}>Logout</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Collapse>
    </Navbar>
  );
};

export default Header;
