import React from 'react';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';

const ModalComponent = ({ showModal, setShowModal, title, handleClose, buttonText, handleAction, children }) => {
  return (
    <BootstrapModal show={showModal} onHide={() => setShowModal(false)}>
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>{title}</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        {children}
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        <Button variant="primary" onClick={handleAction}>
          {buttonText}
        </Button>
      </BootstrapModal.Footer>
    </BootstrapModal>
  );
}

export default ModalComponent;
