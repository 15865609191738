import React, { useEffect, useState } from "react";
import { useRoutes, Route, Routes, Navigate, useLocation, } from "react-router-dom";
import ThemeRoutesWithPayments from "./routes/Router";
import LoginPage from "./components/Login";
// import Signup from "./components/SignUp";
import { useDispatch } from 'react-redux';
import { fetchPayments, fetchReadChat, fetchDevices, fetchCsvData,fecthAllUnreadChat, fetchGetInTouchEmails,fecthUserSurvey } from './redux/actions.js';
import Loader from './layouts/loader/Loader';


const App = () => {
  const routing = useRoutes(ThemeRoutesWithPayments);
  const isLoggedIn = localStorage.getItem("token")
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoader(true);
    dispatch(fecthAllUnreadChat())
    dispatch(fetchPayments())
    dispatch(fetchDevices())
    dispatch(fetchReadChat())
    dispatch(fetchCsvData())
    dispatch(fetchGetInTouchEmails())
    dispatch(fecthUserSurvey())
      .then(() => {
        setLoader(false);
      }).catch(error => {
        console.error('Error fetching payments:', error);
        setLoader(false);
      });
  }, [dispatch]);

  const [overflow, setOverflow] = useState('hidden');
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/dashboard') {
      setOverflow('auto');
    } else {
      setOverflow('hidden');
    }
  }, [location]);

  useEffect(() => {
    document.body.style.overflowY = overflow;
  }, [overflow]);


  return (
    <div>
      <Loader pageLoder={loader} />
      <div className="dark" style={{ overflowY: 'hidden' }}>
        {isLoggedIn === "true" ? (
          routing
        ) : (
          <Routes>
            <Route path="/" element={<LoginPage />} />
            {/* <Route path="/signup" element={<Signup/>}/> */}
            <Route
              path="/*"
              element={<Navigate to="/" replace />}
            />
          </Routes>
        )}
      </div>
    </div>
  );
};

export default App;
