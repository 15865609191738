import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import Loader from '../../../layouts/loader/Loader';
import SDK from '../../../config';

const ActiveDevices = () => {
    const [installStartDate, setInstallStartDate] = useState(null);
    const [installEndDate, setInstallEndDate] = useState(null);
    const [onlineStartDate, setOnlineStartDate] = useState(null);
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(false);
    const [searchResultFound, setSearchResultFound] = useState(true);
    const [isInstallDateDropdownOpen, setIsInstallDateDropdownOpen] = useState(false);
    const [isOnlineDateDropdownOpen, setIsOnlineDateDropdownOpen] = useState(false);

    const formatDate = (date) => {
        if (!date) return '';
        const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        return utcDate.toISOString().split('T')[0];
    }

    const fetchAllDeviceData = async () => {
        try {
            const response = await axios.get(`${SDK.BASE_URL}/get-online-devices/${formatDate(installStartDate)}/${formatDate(installEndDate)}/${formatDate(onlineStartDate)}`);
            setData(response.data);
            setSearchResultFound(true);
        } catch (error) {
            console.error('Error fetching device data:', error);
            setSearchResultFound(false);
        }
    }

    const handleApplyButtonClick = async () => {
        setLoader(true);
        await fetchAllDeviceData();
        setLoader(false);
        setIsInstallDateDropdownOpen(false);
        setIsOnlineDateDropdownOpen(false);
    }

    return (
        <div>
            <Loader pageLoader={loader} />
            <div className='p-0 mx-4 my-3'>
                <div className='d-flex'>
                    <div className="custom-dropdown ms-1">
                        <div className={`dropdown ${isInstallDateDropdownOpen ? 'show' : ''} my-2`}>
                            <input
                                className="dropdown-toggle"
                                type="button"
                                onClick={() => setIsInstallDateDropdownOpen(prevState => !prevState)}
                                value={installStartDate && installEndDate ? `${formatDate(installStartDate)} to ${formatDate(installEndDate)}` : 'Select Install Date'}
                            />
                            <ul className={`dropdown-menu ${isInstallDateDropdownOpen ? 'show' : ''}`}>
                                <li>
                                    <div className="date-inputs" style={{ marginTop: '2px', display: 'flex' }}>
                                        <DatePicker
                                            wrapperClassName="datePicker ms-1 my-2"
                                            selected={installStartDate}
                                            onChange={date => setInstallStartDate(date)}
                                            placeholderText="Start Date"
                                        />
                                        <DatePicker
                                            wrapperClassName="datePicker ms-1 me-2 my-2"
                                            selected={installEndDate}
                                            onChange={date => setInstallEndDate(date)}
                                            placeholderText="End Date"
                                        />
                                        <button className='mx-1 apply  my-2' onClick={handleApplyButtonClick}>Apply</button>
                                        <button className='mx-1 clear my-2' onClick={() => { setInstallStartDate(null); setInstallEndDate(null); }}>Clear</button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="custom-dropdown ms-1">
                        <div className={`dropdown ${isOnlineDateDropdownOpen ? 'show' : ''} my-2`}>
                            <input
                                className="dropdown-toggle"
                                type="button"
                                onClick={() => setIsOnlineDateDropdownOpen(prevState => !prevState)}
                                value={onlineStartDate ? `${formatDate(onlineStartDate)}` : 'Select Online Date'}
                            />
                            <ul className={`dropdown-menu ${isOnlineDateDropdownOpen ? 'show' : ''}`}>
                                <li>
                                    <div className="date-inputs" style={{ marginTop: '2px', display: 'flex' }}>
                                        <DatePicker
                                            wrapperClassName="datePicker ms-1 my-2"
                                            selected={onlineStartDate}
                                            onChange={date => setOnlineStartDate(date)}
                                            placeholderText="Start Date"
                                        />
                                        <button className='mx-1 apply my-2' onClick={handleApplyButtonClick}>Apply</button>
                                        <button className='mx-1 clear my-2' onClick={() => { setOnlineStartDate(null); }}>Clear</button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div style={{ overflowY: "auto" }}>
                    <Table id="" bordered responsive hover>
                        <thead>
                            <tr>
                                <th>Total Devices</th>
                                <td>{data.totalDevices || "00"}</td>
                            </tr>
                            <tr>
                                <th>Lifetime Devices</th>
                                <td>{data.lifetimeDevices || "00"}</td>
                            </tr>
                            <tr>
                                <th>Monthly Devices</th>
                                <td>{data.monthlyDevices || "00"}</td>
                            </tr>
                            <tr>
                                <th>Yearly Devices</th>
                                <td>{data.yearlyDevices || "00"}</td>
                            </tr>
                        </thead>
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default ActiveDevices;
