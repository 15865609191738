// dataSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
  name: 'data',
  initialState: {
    devices: [],
    payments: [],
    readChat: [],
    csvData: [],
    getInTouch: [],
    sevenDaysData: [],
    weeklyStats:[],
    allStats:[],
    userSurvey:[],
    unreadChat:[],
    unresolveChat:[],
    resolveChat:[],
    paymentIssues:[],
  },
  reducers: {
    setDevices: (state, action) => {
      state.devices = action.payload;
    },
    setPayments: (state, action) => {
      state.payments = action.payload;
    },
    setReadChat: (state, action) => {
      state.readChat = action.payload;
    },
    setCsvData: (state, action) => {
      state.csvData = action.payload;
    },
    setGetInTouch: (state, action) => {
      state.getInTouch = action.payload
    },
    setSevenDaysData: (state, action) => {
      state.sevenDaysData = action.payload
    },
    setWeeklyStats: (state, action) => {
      state.weeklyStats = action.payload
    },
    setAllStats : (state, action) => {
      state.allStats = action.payload
    },
    setUserServey : (state, action) => {
      state.userSurvey = action.payload
    },
    setAllUnreadChat : (state, action) => {
      state.unreadChat = action.payload
    },
    setUnResolveChat : (state, action) => {
      state.unresolveChat = action.payload
    },
    setResolveChat : (state, action) => {
      state.resolveChat = action.payload
    },
    setPaymentIssues : (state, action) => {
      state.paymentIssues = action.payload
    },
    
  }
});

export const { setDevices, setPayments, setReadChat, setCsvData, setGetInTouch, setSevenDaysData, setWeeklyStats,setAllStats,setUserServey,setAllUnreadChat,setUnResolveChat,setResolveChat,setPaymentIssues } = dataSlice.actions;

export default dataSlice.reducer;
