import React, { useState, useEffect, useRef } from 'react';
import { Table, Pagination, Card, CardTitle } from 'react-bootstrap';
import { FaSearch } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../layouts/loader/Loader';
import { fecthAllPaymentIssues } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import './asset/view.css';

const PaymentIssueServey = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 30;
    const [editingId, setEditingId] = useState(null);
    const [loader, setLoader] = useState(true);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const editRef = useRef(null);
    const [searchResultFound, setSearchResultFound] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');

    const dispatch = useDispatch();
    const paymentIssues = useSelector(state => state.data.paymentIssues);

    useEffect(() => {
        if (paymentIssues) {
            setData(paymentIssues);
            setFilteredData(paymentIssues);
            setLoader(false);
        }
    }, [paymentIssues]);

    useEffect(() => {
        dispatch(fecthAllPaymentIssues());
    }, [])

    useEffect(() => {
        const filtered = data.filter(item =>
            item.device_ID.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.device_Name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        
        setFilteredData(filtered);
        setSearchResultFound(filtered.length > 0);
        setCurrentPage(1);
    }, [searchTerm, data]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (editRef.current && !editRef.current.contains(event.target)) {
                setEditingId(null);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPageNumbers = () => {
        const totalPages = Math.ceil(filteredData.length / itemsPerPage);
        const pageNumbers = [];

        let startPage = Math.max(1, currentPage - 1);
        let endPage = Math.min(totalPages, currentPage + 1);

        if (endPage - startPage < 2) {
            if (startPage === 1) {
                endPage = Math.min(totalPages, startPage + 2);
            } else {
                startPage = Math.max(1, endPage - 2);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                    {i}
                </Pagination.Item>
            );
        }

        return pageNumbers;
    };

    return (
        <div className='mx-4 mt-3'>
            <Loader pageLoder={loader} />
            <ToastContainer />
            <Card style={{ marginBottom: "7px" }}>
                <CardTitle>
                    <div className='d-flex justify-content-between align-items-center search-div'>
                        <div>
                            <h5 style={{ margin: "", paddingTop: '5px' }}>Payment Issues</h5>
                        </div>
                        <div className="d-flex align-items-center search-input">
                            <input
                                id="searchInput"
                                type="text"
                                style={{ marginRight: '5px' }}
                                placeholder='Search...'
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <FaSearch className='search-icon' />
                        </div>
                    </div>
                </CardTitle>
            </Card>
            <div style={{ maxHeight: `${windowHeight - 232}px`, overflowY: "auto" }}>

                <Table id="dataTable" bordered responsive hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Device ID</th>
                            <th>Device Name</th>
                            <th>Payment Issue</th>
                        </tr>
                    </thead>
                    {searchResultFound ? (
                        <tbody>
                            {currentItems.map((item, index) => (
                                <tr key={index} ref={editingId === index ? editRef : null}>
                                    <th scope="row">{indexOfFirstItem + index + 1}</th>
                                    <td className='userServey'>{item.device_ID}</td>
                                    <td className='userServey'>{item.device_Name}</td>
                                    <td className='userServey'>{item.description}</td>
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <tbody><div style={{ textAlign: 'center', marginTop: '20px' }}>No Data Found</div></tbody>

                    )}
                </Table>

            </div>
            <Pagination>
                <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                />
                {renderPageNumbers()}
                <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={indexOfLastItem >= filteredData.length}
                />
            </Pagination>
        </div>
    );
};

export default PaymentIssueServey;
