import React, { useState, useEffect, useRef } from 'react';
import { Table, Pagination, Card, CardTitle } from 'react-bootstrap';
import { FaSearch, FaDownload } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { fetchGetInTouchEmails } from '../../redux/actions';
import Loader from '../../layouts/loader/Loader';
import './asset/view.css';
import $ from 'jquery'

const GetInTouch = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30;
  const [editingId, setEditingId] = useState(null);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const editRef = useRef(null);
  const [searchResultFound, setSearchResultFound] = useState(true);

  const dispatch = useDispatch();
  const getInTouch = useSelector(state => state.data.getInTouch);

  useEffect(() => {
    if (getInTouch && Array.isArray(getInTouch.emails)) {
      setData(getInTouch.emails.map((email, index) => ({ id: index + 1, email })));
    }
  }, [getInTouch]);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(data.length / itemsPerPage);
    const pageNumbers = [];

    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalPages, currentPage + 1);

    if (endPage - startPage < 2) {
      if (startPage === 1) {
        endPage = Math.min(totalPages, startPage + 2);
      } else {
        startPage = Math.max(1, endPage - 2);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }

    return pageNumbers;
  };

  const handleDownloadCSV = () => {
    const csvData = data.map(item => [item.email]);
    const headers = [['Email']];
    const csvContent = headers.concat(csvData).map(row => row.join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodedUri);
    link.setAttribute('download', 'emails.csv');
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    $('#searchInput').on('keyup', function () {
      const value = $(this).val().toLowerCase();
      const filteredData = getInTouch.emails.filter(item => item.toLowerCase().includes(value));
      setData(filteredData.map((email, index) => ({ id: index + 1, email })));
      setCurrentPage(1);
      setSearchResultFound(filteredData.length > 0);
    });
  }, [getInTouch]);

  return (
    <div className='m-4'>
      <Loader />
      <Card style={{ marginBottom: "7px" }}>
        <CardTitle>
          <div className='d-flex justify-content-between align-items-center search-div '>
            <div>
              <h5 style={{ margin: "", paddingTop: '5px' }}>Emails</h5>
            </div>
            <div className=' d-flex align-items-center justify-content-end'>
              <button className="btn btn-primary me-2" style={{fontSize:'13px'}} onClick={handleDownloadCSV}>
                <FaDownload /> Download CSV
              </button>
              <div className="d-flex align-items-center search-input  w-50">
                <input id="searchInput" type="text" style={{ marginRight: '5px' }} placeholder='Search...' />
                <FaSearch className='search-icon' />
              </div>
            </div>
          </div>
        </CardTitle>
      </Card>
      <div style={{ maxHeight: `${windowHeight - 232}px`, overflowY: "auto" }}>
        {searchResultFound ? (
          <Table id="dataTable" bordered responsive hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((item, index) => (
                <tr key={index} ref={editingId === index ? editRef : null}>
                  <th scope="row">{index + 1}</th>
                  <td>{item.email}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>No Data found</div>
        )}
      </div>
      <Pagination>
        <Pagination.Prev
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {renderPageNumbers()}
        <Pagination.Next
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage * itemsPerPage >= data.length}
        />
      </Pagination>
    </div>
  );
}

export default GetInTouch;
