import React, { useState, useEffect } from "react";
import { Button, Nav, NavItem, Collapse } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { fecthAllUnreadChat } from '../redux/actions';

const navigation = [
  // {
  //   title: "Dashboard",
  //   href: "/dashboard",
  //   icon: "bi bi-display",
  // },
  {
    title: "Devices",
    href: "/devices",
    icon: "bi bi-phone",
  },
  {
    title: "Payments",
    href: "/payments",
    icon: "bi bi-patch-check",
  },
  {
    title: "Chats",
    icon: "bi bi-chat-left-dots",
    submenus: [
      {
        title: "Unread Chats",
        href: "/chats",
        icon: "bi bi-chat-left-text-fill",
      },
      {
        title: "All Chats",
        href: "/readchats",
        icon: "bi bi-chat-left-text",
      },
      {
        title: "UnResolved",
        href: "/unResolved",
        icon: "bi bi-chat-quote-fill",
      },
      {
        title: "Resolved",
        href: "/resolved",
        icon: "bi bi-chat-quote",
      },
    ],
  },
  {
    title: "Get in Touch",
    href: "/getIntouch",
    icon: "bi bi-envelope-paper",
  },
  {
    title: "Uninstall Survey",
    href: "/userSurvey",
    icon: "bi bi-person-lines-fill",
  },
  {
    title: "Payment Issues",
    href: "/payment_issues",
    icon: "bi bi-exclamation-circle",
  },

];

const Sidebar = () => {
  const dispatch = useDispatch();
  const unreadChat = useSelector(state => state.data.unreadChat);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);

  useEffect(() => {
    dispatch(fecthAllUnreadChat());
    const intervalId = setInterval(() => {
      dispatch(fecthAllUnreadChat());
    }, 2000);
    return () => clearInterval(intervalId);
  }, [dispatch]);

  const location = useLocation();

  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  const handleToggle = (index) => {
    if (openMenuIndex === index) {
      setOpenMenuIndex(null);  // Close if the same menu is clicked
    } else {
      setOpenMenuIndex(index); // Open the clicked menu
    }
  };

  return (
    <div className="p-3">
      <div className="d-flex align-items-center">
        <h2 style={{ margin: "auto" }}>Minimis</h2>
        <span className="ms-auto d-lg-none">
          <Button
            close
            size="sm"
            className="ms-auto d-lg-none"
            onClick={() => showMobilemenu()}
          ></Button>
          <div className="second ms-auto d-lg-none">
            <Button
              close
              size="sm"
              className="second ms-auto d-lg-none btn-sm"
              onClick={() => showMobilemenu()}
            >
              <i className="bi bi-list"></i>
            </Button>
          </div>
        </span>
      </div>
      <div className="pt-4">
        <Nav vertical className="sidebarNav">
          {navigation.map((navi, index) => (
            <NavItem key={index} className="sidenav-bg">
              {navi.submenus ? (
                <div style={{ cursor: 'pointer' }}>
                  <div className="nav-link text-secondary py-3 w-100" onClick={() => handleToggle(index)}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div style={{ position: 'relative' }}>
                        <i className={navi.icon} style={{ marginTop: '0px', position: 'absolute', top: '2px', marginInlineEnd: '10px' }}></i>
                        <div className="d-inline-block" style={{ paddingLeft: '33px' }}>{navi.title}
                          {unreadChat.length > 0 && (
                            <span
                              style={{
                                marginLeft: "10px",
                                color: "white",
                                borderRadius: "80px",
                                backgroundColor: "red",
                                fontSize: '13px',
                                width:
                                  unreadChat.length < 10
                                    ? "20px"
                                    : unreadChat.length < 100
                                      ? "30px"
                                      : "37px",
                                padding: "2px 5px",
                                height: "23px",
                              }}
                            >
                              {unreadChat.length}
                            </span>
                          )}
                        </div>
                      </div>
                      <div style={{ marginTop: '3px' }}>
                        {openMenuIndex === index ? <i className="bi bi-chevron-up"></i> : <i className="bi bi-chevron-down"></i>}
                      </div>
                    </div>
                  </div>
                  <Collapse isOpen={openMenuIndex === index}>
                    {navi.submenus.map((submenu, subIndex) => (
                      <Link
                        style={{ fontSize: '15px', marginLeft: '10px' }}
                        key={subIndex}
                        to={submenu.href}
                        className={
                          location.pathname === submenu.href
                            ? "text-primary nav-link py-2"
                            : "nav-link text-secondary py-2"
                        }
                      >
                        <i className={submenu.icon}></i>
                        <span className="ms-3 d-inline-block">
                          {submenu.title}
                          {submenu.title === "Unread Chats" && (
                            <span
                              style={{
                                marginLeft: "10px",
                                color: "white",
                                fontSize: '13px',
                                borderRadius: "80px",
                                backgroundColor: "red",
                                width:
                                  unreadChat.length < 10
                                    ? "20px"
                                    : unreadChat.length < 100
                                      ? "30px"
                                      : "37px",
                                padding: "2px 5px",
                                height: "23px",
                              }}
                            >
                              {unreadChat.length}
                            </span>
                          )}
                        </span>
                      </Link>
                    ))}
                  </Collapse>
                </div>
              ) : (
                <Link
                  to={navi.href}
                  className={
                    location.pathname === navi.href
                      ? "text-primary nav-link py-3"
                      : "nav-link text-secondary py-3"
                  }
                >
                  <i className={navi.icon}></i>
                  <span className="ms-3 d-inline-block">{navi.title}</span>
                </Link>
              )}
            </NavItem>
          ))}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
