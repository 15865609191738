import React, { Fragment } from 'react';
import './asset/dashboard.css';
import CardComponent from './dashboard Component/cardComponent';
import WeeklyChart from './dashboard Component/chartComponent/weeklyChart';
import AllDataInweekChart from './dashboard Component/chartComponent/allDataInweekChart';
import PieChartComponent from './dashboard Component/chartComponent/pieChartComponent';


const DashboardDATA = () => {

    return (
        <Fragment>
            <CardComponent />
            <div className='mx-2 mt-3 d-flex' style={{}}>
                <div className='allStats me-1' style={{ width: '33%', background: '#fff', borderRadius: '3px' }}>
                    <center><p className='mt-3'>Weekly Overview of All Statistics</p></center>
                    <AllDataInweekChart />
                </div>

                <div className='allStats ms-2 me-1' style={{ width: '36%', background: '#fff', borderRadius: '3px' }}>
                    <center><p className='mt-3'>All Devices Statics</p></center>
                    <PieChartComponent />
                </div>
                <div className='ms-2 allStats' style={{ background: '#fff', borderRadius: '3px', width: '32%' }}>
                    <center><p className='mt-3'>Day-by-Day New Device Activity</p></center>
                    <div style={{ width: '92%' }}><WeeklyChart /></div>
                </div>

            </div>
        </Fragment>
    );
};

export default DashboardDATA;
