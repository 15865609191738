import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import { fecthAllStats } from '../../../../redux/actions.js';

const PieChartComponent = () => {
  const [data, setData] = useState([]);
  const [totalDevices, setTotalDevices] = useState(0);
  const dispatch = useDispatch();
  const allStats = useSelector(state => state.data.allStats);

  useEffect(() => {
    dispatch(fecthAllStats());
  }, [dispatch]);

  useEffect(() => {
    if (Array.isArray(allStats) && allStats.length > 0) {
      const activeDevices = allStats.find(stat => stat.activeDevices)?.activeDevices || 0;
      const expiresDevices = allStats.find(stat => stat.expiresDevices)?.expiresDevices || 0;
      const paidDevices = allStats.find(stat => stat.paidDevices)?.paidDevices || 0;
      const totalDevices = allStats.find(stat => stat.totalDevices)?.totalDevices || 0;

      // Transform the API response to the expected format
      const formattedData = [
        { name: 'Active', value: activeDevices },
        { name: 'Paid', value: paidDevices },
        { name: 'Expires', value: expiresDevices },
       
      ];
      setData(formattedData);
      setTotalDevices(totalDevices);
    }
  }, [allStats]);

  const COLORS = ['#007bff', '#00C49F', '#FFBB28'];

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="45%"
          innerRadius={73}
          outerRadius={100}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
          label={({ name, value }) => `${name}: ${value}`}
          labelLine={false}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} style={{fontSize:'13px',fontWeight:"bold"}} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <text x="50%" y="47%" textAnchor="middle" dominantBaseline="middle">
          <tspan x="50%" dy="-1.2em">Total Devices:</tspan>
          <tspan x="50%" dy="1.2em" fontSize="24" fontWeight="800" fill="#000">{totalDevices}</tspan>
        </text>
       
      </PieChart>
    </ResponsiveContainer>
  );
}

export default PieChartComponent;
