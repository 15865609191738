import React, { useState, useEffect, useRef } from 'react';
import { Table, Pagination, Card, CardTitle, Button } from 'react-bootstrap';
import { FaSearch } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import Papa from 'papaparse';
import Loader from '../../layouts/loader/Loader';
import './asset/view.css';

const UserServey = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 30;
    const [editingId, setEditingId] = useState(null);
    const [loader, setLoader] = useState(true);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const editRef = useRef(null);
    const [searchResultFound, setSearchResultFound] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');

    const userSurvey = useSelector(state => state.data.userSurvey);

    useEffect(() => {
        if (userSurvey) {
            setData(userSurvey);
            setFilteredData(userSurvey);
            setLoader(false);
        }
    }, [userSurvey]);

    useEffect(() => {
        const filtered = data.filter(item =>
            item.deviceId.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.deviceName.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredData(filtered);
        setSearchResultFound(filtered.length > 0);
        setCurrentPage(1);
    }, [searchTerm, data]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (editRef.current && !editRef.current.contains(event.target)) {
                setEditingId(null);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPageNumbers = () => {
        const totalPages = Math.ceil(filteredData.length / itemsPerPage);
        const pageNumbers = [];

        let startPage = Math.max(1, currentPage - 1);
        let endPage = Math.min(totalPages, currentPage + 1);

        if (endPage - startPage < 2) {
            if (startPage === 1) {
                endPage = Math.min(totalPages, startPage + 2);
            } else {
                startPage = Math.max(1, endPage - 2);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                    {i}
                </Pagination.Item>
            );
        }

        return pageNumbers;
    };

    const downloadCSV = () => {
        // Filter the data to include only rows where one or more fields are 'Yes'
        const filteredCSVData = filteredData.filter(item => (
            item.servey.tooExpensive ||
            item.servey.notEnough ||
            item.servey.foundAnotherApp ||
            item.servey.dontLike ||
            item.servey.needFeature ||
            item.servey.other
        ));

        const csvData = filteredCSVData.map(item => ({
            DeviceID: item.deviceId,
            DeviceName: item.deviceName,
            TooExpensive: item.servey.tooExpensive ? 'Yes' : 'No',
            DontUseEnough: item.servey.notEnough ? 'Yes' : 'No',
            FoundAnotherApp: item.servey.foundAnotherApp ? `Yes (${item.servey.foundAnotherAppText})` : 'No',
            DontLikeIt: item.servey.dontLike ? `Yes (${item.servey.dontLikeText})` : 'No',
            NeedFeature: item.servey.needFeature ? `Yes (${item.servey.needFeatureText})` : 'No',
            Others: item.servey.other ? item.servey.otherText : 'No'
        }));

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'user_survey.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <div className='mx-4 mt-3'>
            <Loader pageLoder={loader} />
            <ToastContainer />
            <Card style={{ marginBottom: "7px" }}>
                <CardTitle>
                    <div className='d-flex justify-content-between align-items-center search-div'>
                        <div>
                            <h5 style={{ margin: "", paddingTop: '5px' }}>User Survey</h5>
                        </div>
                        <div className="d-flex align-items-center">
                            <Button onClick={downloadCSV} style={{ marginRight: '10px' }}>Download as CSV</Button>
                            <div className="d-flex align-items-center search-input">
                                <input
                                    id="searchInput"
                                    type="text"
                                    style={{ marginRight: '5px' }}
                                    placeholder='Search...'
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <FaSearch className='search-icon' />
                            </div>
                        </div>
                    </div>
                </CardTitle>
            </Card>
            <div style={{ maxHeight: `${windowHeight - 232}px`, overflowY: "auto" }}>

                <Table id="dataTable" bordered responsive hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Device ID</th>
                            <th>Device Name</th>
                            <th>Too Expensive</th>
                            <th>Don't Use Enough</th>
                            <th>Found Another App</th>
                            <th>Don't Like It</th>
                            <th>Need Feature</th>
                            <th>Others</th>
                        </tr>
                    </thead>
                    {searchResultFound ? (
                        <tbody>
                            {currentItems.map((item, index) => (
                                <tr key={index} ref={editingId === index ? editRef : null}>
                                    <th scope="row">{indexOfFirstItem + index + 1}</th>
                                    <td className='userServey'>{item.deviceId}</td>
                                    <td className='userServey'>{item.deviceName}</td>
                                    <td className='userServey'>{item.servey.tooExpensive ? `Yes` : "No"}</td>
                                    <td className='userServey'>{item.servey.notEnough ? `Yes` : "No"}</td>
                                    <td className='userServey'>{item.servey.foundAnotherApp ? `Yes (${item.servey.foundAnotherAppText})` : "No"}</td>
                                    <td className='userServey'>{item.servey.dontLike ? `Yes (${item.servey.dontLikeText})` : "No"}</td>
                                    <td className='userServey'>{item.servey.needFeature ? `Yes (${item.servey.needFeatureText})` : "No"}</td>
                                    <td className='userServey'>{item.servey.other ? item.servey.otherText : "No"}</td>
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <tbody><div style={{ textAlign: 'center', marginTop: '20px' }}>No Data Found</div></tbody>
                    )}
                </Table>

            </div>
            <Pagination>
                <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                />
                {renderPageNumbers()}
                <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={indexOfLastItem >= filteredData.length}
                />
            </Pagination>
        </div>
    );
};

export default UserServey;
