import axios from "axios";
import SDK from "../config";


// fetch All Devices Data 
export const fetchAllDevicesBetweenDate = async (installStartDate, installEndDate) => {
    try {
        const response = await axios.get(`${SDK.BASE_URL}/all-statics/${installStartDate}/${installEndDate}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching Devices:', error);
        throw error;
    }
};


