import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../layouts/loader/Loader';
import $ from 'jquery'
import SDK from '../../../config';
import ModalComponent from './modalComponent';
import { fetchCsvData } from '../../../redux/actions';
import { useDispatch } from 'react-redux';


const ModalBox = ({ showModal, setShowModal }) => {
  const [buttonText, setButtonText] = useState("Upload");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    $("form").on("change", ".file-upload-field", function () {
      $(this)
        .parent(".file-upload-wrapper")
        .attr(
          "data-text",
          $(this)
            .val()
            .replace(/.*(\/|\\)/, "")
        );
    });
  })

  const handleClose = () => {
    setShowModal(false);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };


  //upload CSV in DataBase
  const handleUpload = async () => {
    if (!selectedFile) {
      toast.info('Please select a file');
      return;
    }
    try {
      setLoader(true);
      const formData = new FormData();
      formData.append("file", selectedFile);

      const response = await fetch(SDK.BASE_URL + "/uploadCSV", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        dispatch(fetchCsvData())
        toast.success('File Uploaded Successfully');
        setShowModal(false);
      } else {
        throw new Error("Failed to upload file.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error('File not Uploaded');
    } finally {
      setLoader(false);
    }
  };

  return (
    <div>
      <Loader pageLoder={loader} />
      <ModalComponent
        showModal={showModal}
        setShowModal={setShowModal}
        title="Upload CSV"
        handleClose={handleClose}
        buttonText={buttonText}
        handleAction={handleUpload}
      >
        <div className="container">
          <form className="form">
            <div className="file-upload-wrapper" data-text="Select your CSV file!">
              <input
                name="file-upload-field"
                type="file"
                className="file-upload-field"
                accept=".csv"
                onChange={handleFileChange}
              />
            </div>
          </form>
        </div>
      </ModalComponent>
    </div>
  );
};

export default ModalBox;
