import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Table, Pagination, Card, CardTitle } from 'react-bootstrap';
import $ from 'jquery';
import { FiEdit } from "react-icons/fi";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../layouts/loader/Loader';
import { FaSearch } from "react-icons/fa";
import './asset/view.css';
import { fetchCsvData } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import EmailModal from './modal/emailModal';


const CsvData = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 30;
    const [editingId, setEditingId] = useState(null);
    const [loader, setLoader] = useState(false);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const editRef = useRef(null);
    const [searchResultFound, setSearchResultFound] = useState(true); 
    const [showModal, setShowModal] = useState(false);

    const dispatch = useDispatch();
    const csvData = useSelector(state => state.data.csvData);
  
    useEffect(() => {
      dispatch(fetchCsvData());
    },[]);
  
    useEffect(() => {
      if (csvData) {
        setData(csvData);
      }
    }, [csvData]);

    
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (editRef.current && !editRef.current.contains(event.target)) {
        setEditingId(null);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(data.length / itemsPerPage);
    const pageNumbers = [];

    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalPages, currentPage + 1);

    if (endPage - startPage < 2) {
      if (startPage === 1) {
        endPage = Math.min(totalPages, startPage + 2);
      } else {
        startPage = Math.max(1, endPage - 2);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }

    return pageNumbers;
  };
  
  useEffect(() => {
    $('#searchInput').on('keyup', function () {
      const value = $(this).val().toLowerCase();
      const filteredData = csvData.filter(item => item.email.toLowerCase().includes(value));
      setData(filteredData);
      setCurrentPage(1); 
      setSearchResultFound(filteredData.length > 0); 
    });
  }, [csvData]); 
  

    return (
        <div className='mx-4'>
          <Loader/>
          <ToastContainer />
          <div className=' d-flex justify-content-end my-2' >
            <button style={{background:'#007bff',outline:'none',border:'none',borderRadius:'5px',padding:'4px 15px',color:'white'}} onClick={() => setShowModal(true)}>Add Email</button>
          </div>
          <EmailModal showModal={showModal} setShowModal={setShowModal} />
          <Card  style={{ marginBottom: "7px" }}>
            <CardTitle >
              <div className='d-flex justify-content-between align-items-center search-div'>
                <div>
                  <h5 style={{ margin: "", paddingTop: '5px' }}>Emails</h5>
                </div>
                <div className="d-flex align-items-center search-input">
                  <input id="searchInput" type="text" style={{ marginRight: '5px' }} placeholder='Search...' />
                  <FaSearch className='search-icon' />
                </div>
              </div>
            </CardTitle>
          </Card>
          <div style={{ maxHeight: `${windowHeight - 232}px`, overflowY: "auto" }}>
            {searchResultFound ? (
              <Table id="dataTable" bordered responsive hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Email</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((item, index) => (
                    <tr key={index} ref={editingId === index ? editRef : null}>
                      <th scope="row">{indexOfFirstItem + index + 1}</th>
                      <td>{item.email}</td>
                      <td><FiEdit className='ms-3' style={{ color: '#ff0000', cursor: 'pointer' }}/></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div style={{ textAlign: 'center', marginTop: '20px' }}>No Data found</div>
            )}
          </div>
          <Pagination>
            <Pagination.Prev
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {renderPageNumbers()}
            <Pagination.Next
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={indexOfLastItem >= data.length}
            />
          </Pagination>
        </div>
      );
}

export default CsvData
