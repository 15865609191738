import axios from "axios";
import SDK from "../config";


// fetch All Devices Data 
export const fetchAllDevices = async () => {
    try {
      const response = await axios.get(`${SDK.BASE_URL}/all-chatsmessage`);
      return response.data;
    } catch (error) {
      console.error('Error fetching Devices:', error);
      throw error;
    }
  };

  


  // fetch All Chat Data 
  export const fetchAllChat = async () => {
      try {
        const response = await axios.get(`${SDK.BASE_URL}/all-users-chats`);
        return response.data;
      } catch (error) {
        console.error('Error fetching Devices:', error);
        throw error;
      }
    };
  


// fetch All Messaages By ID 
export const fetchAllMessages = async (selectedDeviceId) => {
    try {
      const response = await axios.get(`${SDK.BASE_URL}/admin/chat/${selectedDeviceId}`);
      return response.data;
    } catch (error) {
      console.error('Error Messages:', error);
      throw error;
    }
  };
  

// post Message Data in Databse
export const postMessage = async (messageData) => {
    try {
      const result = await axios.post(`${SDK.BASE_URL}/admin/chat/`, messageData);
      return result.data;
    } catch (error) {
      console.error('Error posting data:', error);
      throw error; 
    }
  };



