import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Table, Pagination, Card, CardTitle } from 'react-bootstrap';
import $ from 'jquery';
import { FiEdit } from "react-icons/fi";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../layouts/loader/Loader';
import { updateDevices } from '../../api/updateApi'
import { FaSearch } from "react-icons/fa";
import './asset/view.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDevices } from '../../redux/actions';

const Devices = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30;
  const [editingId, setEditingId] = useState(null);
  const [editedName, setEditedName] = useState('');
  const [editedId, setEditedId] = useState('');
  const [editId, setEditId] = useState('');
  const [loader, setLoader] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const editRef = useRef(null);
  const [searchResultFound, setSearchResultFound] = useState(true);

  const devices = useSelector(state => state.data.devices);


  useEffect(() => {
    if (devices) {
      setData(devices);
    }
  }, [devices]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (editRef.current && !editRef.current.contains(event.target)) {
        setEditingId(null);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(data.length / itemsPerPage);
    const pageNumbers = [];

    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalPages, currentPage + 1);

    if (endPage - startPage < 2) {
      if (startPage === 1) {
        endPage = Math.min(totalPages, startPage + 2);
      } else {
        startPage = Math.max(1, endPage - 2);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }

    return pageNumbers;
  };

  const handleEdit = (index, id, name) => {
    setEditingId(index);
    setEditedId(id);
    setEditedName(name);
    setEditId(id);
  };

  const handleSave = async (index) => {
    setEditingId(null);
    const newData = [...data];

    newData[index] = {
      ...newData[index],
      device_ID: editId,
      device_name: editedName
    };

    setData(newData);

    setLoader(true)
    try {
      await updateDevices(editedId, {
        "device_name": editedName,
        "device_ID": editId
      });
      toast.success('Device updated successfully.');
    } catch (error) {
      console.error('Error updating device:', error);
      toast.error('Failed to update device. Please try again later.');
    }
    finally {
      setLoader(false)
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    $('#searchInput').on('keyup', function () {
      const value = $(this).val().toLowerCase();
      if (data) {
        const filteredData = devices.filter(item =>
          item.device_ID.toLowerCase().includes(value) ||
          item.device_name.toLowerCase().includes(value) ||
          item.device_OS.toLowerCase().includes(value)
        );
        setSearchResultFound(filteredData.length > 0);

        setData(filteredData);
      }
    });
  }, [devices]);

  return (
    <div className='m-4'>
      <Loader pageLoder={loader} />
      <ToastContainer />
      <Card style={{ marginBottom: "7px" }}>
        <CardTitle >
          <div className='d-flex justify-content-between align-items-center search-div'>
            <div>
              <h5 style={{ margin: "", paddingTop: '5px' }}>Devices</h5>
            </div>
            <div className="d-flex align-items-center search-input">
              <input id="searchInput" type="text" style={{ marginRight: '5px' }} placeholder='Search...' />
              <FaSearch className='search-icon' />
            </div>
          </div>
        </CardTitle>
      </Card>
      <div style={{ maxHeight: `${windowHeight - 232}px`, overflowY: "auto" }}>
        {searchResultFound ? (
          <Table id="dataTable" bordered responsive hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Device ID</th>
                <th>Device Name</th>
                <th>Device OS</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index} ref={editingId === index ? editRef : null}>
                  <th scope="row">{indexOfFirstItem + index + 1}</th>
                  <td>
                    {editingId === index ? (
                      <input type="text" value={editId} onChange={(e) => setEditId(e.target.value)} />
                    ) : (
                      item.device_ID
                    )}
                  </td>
                  <td>{editingId === index ? <input type="text" value={editedName} onChange={(e) => setEditedName(e.target.value)} /> : item.device_name}</td>
                  <td>{item.device_OS}</td>
                  <td>
                    {editingId === index ? (
                      <button className='ms-2' style={{ border: 'none', outline: 'none', background: '#105901', fontSize: '14px', color: "white", padding: "2px 9px", borderRadius: '5px' }} onClick={() => handleSave(index)}>Save</button>
                    ) : (
                      <FiEdit className='ms-3' style={{ color: '#ff0000', cursor: 'pointer' }} onClick={() => handleEdit(index, item.device_ID, item.device_name)} />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>No Data found</div>
        )}
      </div>
      <Pagination>
        <Pagination.Prev
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {renderPageNumbers()}
        <Pagination.Next
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={indexOfLastItem >= data.length}
        />
      </Pagination>
    </div>
  );
};

export default Devices;
