import React, { useState, useEffect, useRef } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { Table } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { fetchAllDevices, fetchAllMessages, postMessage } from '../../../api/chatApi';
import sending from '../asset/send.png';
import Loader from '../../../layouts/loader/Loader';
import { FaArrowDown } from 'react-icons/fa6';
import SDK from '../../../config';
import { IoChatbubblesOutline } from 'react-icons/io5';
import { LuEye, LuEyeOff } from "react-icons/lu";
import axios from 'axios';
import $ from 'jquery';
import { CiSearch } from "react-icons/ci";

const NewChats = ({ socket }) => {
    const [selectedDeviceId, setSelectedDeviceId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [showDevicesMenu, setShowDevicesMenu] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [textMessage, setTextMessage] = useState('');
    const [showScrollToBottomArrow, setShowScrollToBottomArrow] = useState(false);
    const chatContainerRef = useRef(null);
    const [devicePending, setDevicePending] = useState([]);
    const [isEyeIconActive, setIsEyeIconActive] = useState(false);
    const [selectDeviceName, setSelectDeviceName] = useState(null)
    const [showSearchInput, setShowSearchInput] = useState(false);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);


    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    // Fetch devices on component mount
    useEffect(() => {
        fetchAllDevicesData();
        const intervalId = setInterval(fetchAllDevicesData, 2000);
        return () => clearInterval(intervalId);
    }, []);





    // Fetch messages and set up socket listeners when selectedDeviceId or socket changes
    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        socket.on('chatMessage', (messageData) => {
            setMessages(prevMessages => [...prevMessages, messageData]);
        });

        socket.on('socketUpdate', () => {
            fetchAllMessagesData(selectedDeviceId);
        });

        socket.on('chatMessage', () => {
            fetchAllMessagesData(selectedDeviceId);
        });

        window.addEventListener('click', handleWindowClick);
        return () => {
            window.removeEventListener('resize', handleResize);
            socket.off('chatMessage');
            socket.off('socketUpdate');
            window.removeEventListener('click', handleWindowClick);
        };
    }, [selectedDeviceId, socket]);



    const handleWindowClick = () => {
        fetchAllMessagesData(selectedDeviceId);
        // if (selectedDeviceId !== null) {
        //     setTimeout(() => {
        //         updateMessageStatus(selectedDeviceId);
        //     }, 1000);
        // }
    };


    // Fetch all devices function
    const fetchAllDevicesData = async () => {
        try {
            const result = await fetchAllDevices();
            setDevicePending(result);
        } catch (error) {
            console.error('Error fetching devices:', error);
        }
    };



    // Handle link click function
    useEffect(() => {
        const handleLinkClick = (event) => {
            const target = event.target;
            if (target.classList.contains('clickable-link')) {
                const url = target.getAttribute('data-url');
                window.open(url, '_blank');
            } else if (target.tagName === 'A' && target.getAttribute('href') && !target.getAttribute('href').startsWith('javascript')) {
                window.open(target.getAttribute('href'), '_blank');
            }
        };
        const container = chatContainerRef.current;
        container.addEventListener('click', handleLinkClick);
        return () => {
            container.removeEventListener('click', handleLinkClick);
        };
    }, []);




    // Fetch all messages function
    const fetchAllMessagesData = async (selectedDeviceId) => {
        try {
            const result = await fetchAllMessages(selectedDeviceId);
            const messagesWithLinks = result.map(message => {
                let messageWithLinks = message.message;
                if (message.message.includes('https://shorturl.at/dhkTV')) {
                    messageWithLinks = messageWithLinks.replace('https://shorturl.at/dhkTV', '<span class="clickable-link link-primary" data-url="https://shorturl.at/dhkTV"> Here </span>');
                } else {
                    const regex = /(https?:\/\/[^\s]+)/g;
                    messageWithLinks = message.message.replace(regex, '<a href="$1" target="_blank">$1</a>');
                }
                return {
                    ...message,
                    message: messageWithLinks
                };
            });
            setMessages(messagesWithLinks);
            setMessages(prevMessages => [...prevMessages, result]);
        } catch (error) {
            console.error('Error fetching devices:', error);
        }
    };



    // Event listener for resize
    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 837);
    };



    // Handle key press event for sending message
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSendMessage();
        }
    };



    // Send message function
    const handleSendMessage = async () => {
        const messageData = {
            deviceId: selectedDeviceId,
            message: textMessage,
            sentFrom: 'admin'
        };
        try {
            await postMessage(messageData);
            scrollToBottom();
            setMessages(prevMessages => [...prevMessages, messageData]);
            setTextMessage('');
            socket.emit('chatMessage', messageData);
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };


    const handleDeviceClick = async (deviceId) => {
        // await updateMessageStatus(deviceId);
        const selectedDevice = devicePending.find(device => device.device_ID === deviceId);
        const selectedDeviceName = selectedDevice ? selectedDevice.device_name : null;
        setSelectedDeviceId(deviceId);
        setSelectDeviceName(selectedDeviceName);
        setShowDevicesMenu(false);
        await fetchAllMessagesData(deviceId);
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            scrollToBottom();
        }, 2000);

    };



    // Update message status function
    // const updateMessageStatus = async (deviceId) => {
    //     try {
    //         const counts = await axios.put(SDK.BASE_URL + `/admin/chat/` + deviceId);
    //         return counts;
    //     } catch (error) {
    //         return error;
    //     }
    // };


    // Scroll to bottom function
    const scrollToBottom = () => {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    };


    // Scroll to bottom if at bottom function
    const scrollToBottomIfAtBottom = () => {
        const container = chatContainerRef.current;
        if (container.scrollHeight - container.scrollTop === container.clientHeight) {
            scrollToBottom();
        } else {
            setShowScrollToBottomArrow(true);
        }
    };


    // Scroll to bottom arrow click function
    const scrollToBottomArrowClick = () => {
        scrollToBottom();
        setShowScrollToBottomArrow(false);
    };


    // Function to format time (exclude seconds)
    function formatTime(timestamp) {
        const date = new Date(timestamp);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const timeString = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
        return timeString;
    }


    // Handle chat container scroll function
    const handleChatContainerScroll = () => {
        const container = chatContainerRef.current;
        if (container.scrollHeight - container.scrollTop === container.clientHeight) {
            setShowScrollToBottomArrow(false);
        } else {
            setShowScrollToBottomArrow(true);
        }
    };


    const handleEyeIconClick = async () => {
        await setMessages([]);
        setIsEyeIconActive(prevState => !prevState);
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setMessages([]);
            setSelectDeviceName(null)
        }, 1000);

        // await updateMessageStatus(selectedDeviceId);
        setSelectedDeviceId(null)
        setSelectDeviceName(null)
        setMessages([]);
    }



    const handleSearchIconClick = (e) => {
        e.stopPropagation();
        setShowSearchInput(true);
    };

    const handleInputClick = (e) => {
        e.stopPropagation();
    };

    const handleOutsideClick = () => {
        setShowSearchInput(false);
        $('.device-row').show();
    };


    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);



    const handleSearch = () => {
        const searchText = $('#searchInput').val().toLowerCase();
        let foundDevices = false;

        $('.device-row').each(function () {
            const deviceName = $(this).find('.device-name').text().toLowerCase();
            if (deviceName.includes(searchText)) {
                $(this).show();
                foundDevices = true;
            } else {
                $(this).hide();
            }
        });

        if (!foundDevices && searchText !== '') {
            $('#noDeviceFoundMessage').show();
        } else {
            $('#noDeviceFoundMessage').hide();
        }

        if (searchText === '') {
            $('.device-row').show();
        }
    };



    // Group messages by date
    const groupMessagesByDate = (messages) => {
        const groupedMessages = {};
        messages.forEach(message => {
            if (message && message.createdAt) {
                const date = new Date(message.createdAt).toDateString();
                if (!groupedMessages[date]) {
                    groupedMessages[date] = [];
                }
                groupedMessages[date].push(message);
            }
        });
        return groupedMessages;
    };


    // Also, make sure to handle cases where message or message.createdAt 
    const renderMessagesByDate = (groupedMessages) => {
        return Object.keys(groupedMessages).map(date => (
            <div key={date} style={{ marginBottom: '20px' }}>
                <div style={{ position: 'relative', width: '100%' }}>
                    <p style={{ position: 'absolute', top: '-14px', left: '50%', transform: 'translateX(-50%)', background: 'white', zIndex: '1000', backgroundColor: '#fff', padding: '0 10px' }}>{date}</p>
                    <hr style={{ width: '100%', zIndex: '1' }} />
                </div>
                {groupedMessages[date].map((message, index) => (
                    <div key={index} style={{ marginBottom: '10px', display: 'flex', justifyContent: message.sentFrom === 'admin' ? 'flex-end' : 'flex-start' }}>
                        <div
                            style={{
                                padding: '10px 27px 17px 10px',
                                margin: '5px',
                                maxWidth: '70%',
                                height: 'auto',
                                textAlign: message.sentFrom === 'admin' ? '' : 'left',
                                backgroundColor: message.sentFrom === 'admin' ? '#e0e0e0' : '#a2e8a2',
                                borderRadius: '10px',
                                position: 'relative',
                            }}
                        >
                            <div dangerouslySetInnerHTML={{ __html: message && message.message }}></div>
                            {message.createdAt && (
                                <p
                                    style={{
                                        fontSize: '9px',
                                        position: 'absolute',
                                        bottom: '-15px',
                                        right: '5px',
                                        color: '#666',
                                    }}
                                >
                                    {formatTime(message.createdAt)}
                                </p>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        ));
    };



    return (
        <div className='' style={isSmallScreen ? { margin: '0' } : { margin: '20px' }}>
            <Loader pageLoder={loading} />
            <div style={{ overflow: 'hidden' }} >
                <div className='row' >
                    <div className='col-lg-4 col-md-4 d-none d-md-block' >
                        <Card>
                            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
                                <IoChatbubblesOutline className='me-3 mb-1' style={{ fontSize: '22px' }} />
                                Chats List
                            </CardTitle>
                            <CardBody style={{ overflow: "hidden" }}>
                                <div style={{ overflowY: "auto", height: `${windowHeight - 227}px`, }}>
                                    <Table bordered hover>
                                        <thead>
                                            <tr>
                                                {!showSearchInput ? (
                                                    <th>
                                                        Device Name
                                                        <span className='float-end' onClick={handleSearchIconClick} ><CiSearch style={{ fontSize: "18px" }} /></span>
                                                    </th>
                                                ) : (
                                                    <td>
                                                        <input type="text" placeholder="Search Device"
                                                            id="searchInput"
                                                            onChange={handleSearch}
                                                            onClick={handleInputClick} className="form-control" />
                                                    </td>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectDeviceName !== null && (
                                                <tr className='selectDevice' key={selectedDeviceId}>
                                                    <td>{selectDeviceName}</td>
                                                </tr>
                                            )}


                                            {devicePending && devicePending
                                                .filter(device => device.device_ID !== selectedDeviceId)
                                                .map((device) => (
                                                    <tr key={device.device_ID} onClick={() => handleDeviceClick(device.device_ID)} className="device-row">
                                                        <td className="device-name">
                                                            {device.device_name}
                                                            {device.device_ID !== selectedDeviceId && (
                                                                <span
                                                                    style={{
                                                                        marginLeft: "20px",
                                                                        paddingLeft: "5px",
                                                                        backgroundColor: "red",
                                                                        display: "inline-block",
                                                                        borderRadius: "80px",
                                                                        color: "white",
                                                                        width: (() => {
                                                                            const messageLength = device.unread_count || 0;
                                                                            if (messageLength < 10) {
                                                                                return "20px";
                                                                            } else if (messageLength >= 10 && messageLength < 100) {
                                                                                return "30px";
                                                                            } else {
                                                                                return "37px";
                                                                            }
                                                                        })(),
                                                                    }}
                                                                >
                                                                    {device.unread_count}
                                                                </span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            <tr id="noDeviceFoundMessage" style={{ display: 'none' }}>
                                                <td colSpan="1">No Result found</td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>

                    </div>

                    <div className='col-lg-8 col-md-8'>
                        <Card>
                            <CardTitle tag="h6" className="border-bottom p-3 mb-0 d-flex justify-content-between align-items-center">
                                <div>
                                    {isSmallScreen ? (
                                        <i className="bi bi-card-text me-2" onClick={() => setShowDevicesMenu(true)}></i>
                                    ) : (
                                        <i className="bi bi-card-text me-2"></i>
                                    )}
                                    {selectedDeviceId === null ? "Select Chat" : (selectDeviceName || "Select Chat")}
                                </div>
                                <div>
                                    {selectedDeviceId !== null ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                            }}
                                            onMouseEnter={() => setIsEyeIconActive(true)}
                                            onMouseLeave={() => setIsEyeIconActive(false)}
                                            onClick={handleEyeIconClick}
                                        >
                                            {isEyeIconActive ? (
                                                <span style={{ marginRight: '5px', fontSize: '14px' }}>Mark as read</span>
                                            ) : null}
                                            {isEyeIconActive ? (
                                                <LuEyeOff style={{ fontSize: '20px', }} />
                                            ) : (
                                                <LuEye style={{ fontSize: '20px', }} />
                                            )}
                                        </div>
                                    ) : ""}
                                </div>
                            </CardTitle>

                            <CardBody >
                                <div
                                    className='cardBody'
                                    ref={chatContainerRef}
                                    onScroll={handleChatContainerScroll}
                                    style={{
                                        height: `${windowHeight - 275}px`,
                                        overflowY: 'auto',
                                        overflowX: 'hidden'
                                    }}
                                >


                                    {renderMessagesByDate(groupMessagesByDate(messages))}


                                    {showScrollToBottomArrow && (
                                        <button
                                            onClick={scrollToBottomArrowClick}
                                            style={{
                                                position: 'absolute',
                                                bottom: '81px',
                                                right: '41px',
                                                background: 'transparent',
                                                border: 'none',
                                                cursor: 'pointer',
                                                backgroundColor: '#007BFF',
                                                borderRadius: '50%',
                                                width: '34px',
                                                height: '34px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <FaArrowDown style={{ color: 'white', fontSize: '18px' }} />
                                        </button>
                                    )}
                                </div>


                                <div style={{ padding: '10px', height: "55px", border: '1px solid #ccc', display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ width: '90%', }}>
                                        <textarea
                                            style={{ width: '100%', marginRight: '10px', padding: '5px', height: "35px", resize: 'none' }}
                                            placeholder="Type your message here"
                                            value={textMessage}
                                            onChange={(e) => setTextMessage(e.target.value)}
                                            onKeyPress={handleKeyPress}
                                        />
                                    </div>
                                    <div>
                                        <button
                                            style={{
                                                color: 'white',
                                                border: 'none',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: "35px",
                                                marginLeft: "5px",
                                                paddingRight: "5px"
                                            }}
                                            onClick={handleSendMessage}
                                        >
                                            <img
                                                src={sending}
                                                alt="Send Icon"
                                                style={{ marginRight: '5px', paddingLeft: "10px", height: '25px', width: '30px', }}
                                            />
                                        </button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>


                <Offcanvas show={showDevicesMenu && isSmallScreen} style={{ width: '60%' }} onHide={() => setShowDevicesMenu(false)} placement="start">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Device Name</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Table bordered hover>
                            <tbody>
                                {devicePending && devicePending.map((device) => (
                                    <tr key={device.device_ID} onClick={() => handleDeviceClick(device.device_ID)}>
                                        <td>
                                            {device.device_name}
                                            <span
                                                style={{
                                                    marginLeft: "20px",
                                                    paddingLeft: "2px",
                                                    paddingRight: '2px',
                                                    backgroundColor: "red",
                                                    display: "inline-block",
                                                    borderRadius: "80px",
                                                    color: "white",
                                                    width: (() => {
                                                        const messageLength = device.pendingMessages || 0;

                                                        if (messageLength < 10) {
                                                            return "24px";
                                                        } else if (messageLength >= 10 && messageLength < 100) {
                                                            return "30px";
                                                        } else {
                                                            return "37px";
                                                        }
                                                    })
                                                }}
                                            >

                                                {device.unread_count}

                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </div>
    );
}

export default NewChats;