import { lazy, useEffect } from "react";
import { Navigate } from "react-router-dom";
import Payments from "../views/ui/Payments.js";
import socketIO from 'socket.io-client';
import SDK from "../config.js";
import NewChats from "../views/ui/extraPages/newChat.js";
import DashboardDATA from "../views/ui/dashboard.js";
import Devices from '../views/ui/Devices.js';
import Chats from '../views/ui/chatComponent/Chats.js';
import FullLayout from '../layouts/FullLayout.js';
import ReadChat from '../views/ui/chatComponent/ReadChat.js';
import CsvData from '../views/ui/CsvData.js';
import GetInTouch from '../views/ui/getInTouch.js'
import UserServey from "../views/ui/UninstallServey.js";
import Statics from "../views/ui/statics.js";
import UnResolvedChat from "../views/ui/chatComponent/unResolvedChat.js";
import ResolvedChat from "../views/ui/chatComponent/ResolvedChat.js";
import PaymentIssueServey from "../views/ui/paymentIssueServey.js";

const socket = socketIO.connect(SDK.BASE_URL);


/*****Routes******/
const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/devices" /> },
      { path: "/dashboard", exact: true, element: <DashboardDATA /> },
      { path: "/devices", exact: true, element: <Devices /> },
      { path: "/payments", exact: true, element: <Payments /> },
      { path: "/csvData", exact: true, element: <CsvData /> },
      { path: "/getIntouch", exact: true, element: <GetInTouch /> },
      { path: "/chats", exact: true, element: <Chats socket={socket} /> },
      { path: "/newchats", exact: true, element: <NewChats socket={socket} /> },
      { path: "/readchats", exact: true, element: <ReadChat socket={socket} /> },
      { path: "/unResolved", exact: true, element: <UnResolvedChat socket={socket} /> },
      { path: "/resolved", exact: true, element: <ResolvedChat socket={socket} /> },
      { path: "/statics", exact: true, element: <Statics/> },
      { path: "/userSurvey", exact: true, element: <UserServey/> },
      { path: "/payment_issues", exact: true, element: <PaymentIssueServey/> },
    ],
  },
];

export default ThemeRoutes;
