import axios from "axios";
import SDK from "../config";



// Update Devices Data
export const updateDevices = async (editedId, newData) => {
  try {
    const response = await axios.put(`${SDK.BASE_URL}/id/info/${editedId}`, newData);
    return response.data;
  } catch (error) {
    console.error('Error Messages:', error);
    throw error;
  }
};



// Update Payment Data
export const updatePaymentData = async (editedId, newData) => {
  try {
    const response = await axios.put(`${SDK.BASE_URL}/id/payment/${editedId}`, newData);
    return response.data;
  } catch (error) {
    console.error('Error Messages:', error);
    throw error;
  }
};