// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media screen and (max-width: 767px) {
    .hide-on-small-and-tablet {
      display: none; /* hide the div on small screens */
    }
    .smallphone{
      padding: 5px!important;
    }
  }
  
  /* Media query for tablet screens (768px to 1023px) */
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .hide-on-small-and-tablet {
      display: none; /* hide the div on tablet screens */
    }
    .smallphone{
      padding: 5px!important;
    }
  }

  .hide-on-small-and-tablet:hover{
    background: #b6b5b2!important;
  }
  .btHover:hover{
    background: #b6b5b2!important;
  }`, "",{"version":3,"sources":["webpack://./src/layouts/layoutStyle.css"],"names":[],"mappings":";AACA;IACI;MACE,aAAa,EAAE,kCAAkC;IACnD;IACA;MACE,sBAAsB;IACxB;EACF;;EAEA,qDAAqD;EACrD;IACE;MACE,aAAa,EAAE,mCAAmC;IACpD;IACA;MACE,sBAAsB;IACxB;EACF;;EAEA;IACE,6BAA6B;EAC/B;EACA;IACE,6BAA6B;EAC/B","sourcesContent":["\r\n@media screen and (max-width: 767px) {\r\n    .hide-on-small-and-tablet {\r\n      display: none; /* hide the div on small screens */\r\n    }\r\n    .smallphone{\r\n      padding: 5px!important;\r\n    }\r\n  }\r\n  \r\n  /* Media query for tablet screens (768px to 1023px) */\r\n  @media screen and (min-width: 768px) and (max-width: 1023px) {\r\n    .hide-on-small-and-tablet {\r\n      display: none; /* hide the div on tablet screens */\r\n    }\r\n    .smallphone{\r\n      padding: 5px!important;\r\n    }\r\n  }\r\n\r\n  .hide-on-small-and-tablet:hover{\r\n    background: #b6b5b2!important;\r\n  }\r\n  .btHover:hover{\r\n    background: #b6b5b2!important;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
