import React, { useState } from 'react';
import '../assets/Pages.css';
import logo from "../assets/logo.svg";
import { Link, useNavigate } from 'react-router-dom';
import SDK from "../config";
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../layouts/loader/Loader';

const LoginPage = () => {
  const navigate = useNavigate();
  const [pageLoder, setPageLoder] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setPageLoder(true);
  
    try {
      const data = {
        email,
        password
      };
  
      await axios.post(SDK.BASE_URL + `/admin/login/`, data)
        .then(() => {
          localStorage.setItem("token", true);
          toast.success('Login Successful');
        })
        
      navigate('/');
      window.location.reload(true);
    } catch (error) {
      console.error('Error:', error.message);
      toast.error('Invalid Credentials');
    } finally {
      setPageLoder(false);
    }
  }
  

  return (
    <div>
    <Loader pageLoder={pageLoder}/>
      <ToastContainer />
      <div className='loginPage'>
        <div className='loginBox'>
          <center><img src={logo} alt="" /></center>
          <div className='loginDiv'>
            <center><p>Login to your Account</p></center>
            <form onSubmit={handleLogin}>
              <input
                type="text"
                placeholder='Enter Your Email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder='Enter Your Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <button type="submit">Login</button>
            </form>
            {/*<div style={{ paddingBottom: "20px", textAlign: "center" }}>
              <Link to={'/signup'} style={{ color: "blue" }}>
                create new account! </Link>
            </div>
           */}
          </div>

        </div>
      </div>
    </div>
  );
};

export default LoginPage;
