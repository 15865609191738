import axios from 'axios';
import React, { Fragment,useMemo, useEffect } from 'react';
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import { useDispatch,useSelector } from 'react-redux';
import { fecthLastSevenDays } from '../../../../redux/actions.js';

const WeeklyChart = () => {
    const dispatch = useDispatch();
    const sevenDaysData = useSelector(state => state.data.sevenDaysData);

    useEffect(() => {
        dispatch(fecthLastSevenDays())
    }, [dispatch]);

    const data = useMemo(() => sevenDaysData || [], [sevenDaysData]);


    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const date = payload[0].payload.date;
            return (
                <div className="custom-tooltip">
                    <p className="label">{`Date: ${date}`}</p>
                    <p className="intro">{`Total Devices: ${payload[0].value}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <Fragment>
        <ResponsiveContainer width="100%" height={300}>
            <ComposedChart
                width={550}
                height={300}
                data={data}
                margin={{
                    top: 0,
                    right: 0,
                    left: -20,
                    bottom: 0,
                }}
            >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="dayName" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="totalDevices" fill="#007bff" barSize={20}/>
                <Line type="monotone" dataKey="totalDevices" stroke="#007bff" />
            </ComposedChart>
        </ResponsiveContainer>
        </Fragment>
    )
}

export default WeeklyChart
