import React, { useState, useEffect, useRef } from 'react';
import { Table, Pagination, Card, CardTitle } from 'react-bootstrap';
import { FiEdit } from "react-icons/fi";
import $ from 'jquery';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../layouts/loader/Loader';
import { updatePaymentData } from '../../api/updateApi';
import { FaSearch } from "react-icons/fa";
import { MdCloudUpload } from "react-icons/md";
import './asset/view.css';
import ModalBox from './modal/csvModal';
import { useDispatch, useSelector } from 'react-redux';
import { FaRegEye } from "react-icons/fa";
import { fetchPayments } from '../../redux/actions';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';



const Payments = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30;
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedPaymentType, setEditedPaymentType] = useState('');
  const [editedExpiryDate, setEditedExpiryDate] = useState('');
  const [loader, setLoader] = useState(false);
  const [searchResultFound, setSearchResultFound] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedDateOption, setSelectedDateOption] = useState(null);
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  const [installStartDate, setInstallStartDate] = useState(null);
  const [installEndDate, setInstallEndDate] = useState(null);
  const [expiryStartDate, setExpiryStartDate] = useState(null);
  const [expiryEndDate, setExpiryEndDate] = useState(null);
  const [isExpiryDateDropdownOpen, setIsExpiryDateDropdownOpen] = useState(false);


  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const editRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate()
  const dropdownRef = useRef(null);
  const dateDropdownRef = useRef(null);

  const dispatch = useDispatch();
  const payments = useSelector(state => state.data.payments);


  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (editRef.current && !editRef.current.contains(event.target)) {
        setEditingIndex(null);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);



  useEffect(() => {
    if (payments.data) {
      let filteredData = payments.data;
      if (selectedOption) {
        filteredData = filteredData.filter(item =>
          item.paymentFlows.some(flow => flow.payment_type && flow.payment_type.toLowerCase() === selectedOption.toLowerCase())
        );
      }
      setSearchResultFound(filteredData.length > 0);
      setData(filteredData);
    }
  }, [payments, selectedOption]);


  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data ? data.slice(indexOfFirstItem, indexOfLastItem) : [];
  const totalPages = Math.ceil(data.length / itemsPerPage);


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const handleEditClick = (index) => {
    setEditingIndex(index);
    const paymentFlow = currentItems[index]?.paymentFlows[0];
    setEditedPaymentType(paymentFlow?.payment_type || '');
    setEditedExpiryDate(paymentFlow?.payment_expiry_date?.split('T')[0] || '');
  };



  const handleSaveClick = async (index) => {
    setLoader(true);
    try {
      await updatePaymentData(currentItems[index].device_ID, {
        payment_check: true,
        payment_type: editedPaymentType,
        payment_expiry_date: editedExpiryDate
      });

      dispatch(fetchPayments())
      const updatedItem = {
        ...currentItems[index],
        payment_check: true,
        paymentFlows: [{
          ...currentItems[index].paymentFlows[0],
          payment_type: editedPaymentType,
          payment_expiry_date: editedExpiryDate
        }]
      };

      const newData = [...data];
      const dataIndex = indexOfFirstItem + index;
      newData[dataIndex] = updatedItem;
      setData(newData);

      setEditedPaymentType('');
      setEditedExpiryDate('');

      toast.success("Payment updated successfully");
    } catch (error) {
      console.error('Error updating data:', error);
      toast.error("Failed to update payment");
    } finally {
      setLoader(false);
      setEditingIndex(null);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalPages, currentPage + 1);

    if (endPage - startPage < 2) {
      if (startPage === 1) {
        endPage = Math.min(totalPages, startPage + 2);
      } else {
        startPage = Math.max(1, endPage - 2);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }

    return pageNumbers;
  };



  useEffect(() => {
    $('#searchInput').on('keyup', function () {
      const value = $(this).val().toLowerCase();
      if (payments.data) {
        const filteredData = payments.data.filter(item =>
          (item.paymentFlows.some(flow => flow.payment_type && flow.payment_type.toLowerCase().includes(value))) ||
          (item.device_ID && item.device_ID.toLowerCase().includes(value)) ||
          (item.payment_expiry_date && item.payment_expiry_date.includes(value)) ||
          (item.payment_date && item.payment_date.includes(value)) ||
          (item.lifeSubscription[0]?.email && item.lifeSubscription[0].email.toLowerCase().includes(value))
        );
        setSearchResultFound(filteredData.length > 0);
        setData(filteredData);
      }
    });

  }, [payments]);


  //All Data Dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option === "All Data" ? null : option);
    setIsDropdownOpen(false);
  };


  //Select Install Date Dropdown
  const toggleDateDropdown = () => {
    setIsDateDropdownOpen(prevState => !prevState);
  }

  useEffect(() => {
    if (selectedDateOption === 'Install Date' && installStartDate && installEndDate) {
      const filteredData = payments.data.filter(item => {
        const installDate = new Date(item.appDetails[0].first_install);
        return installDate >= installStartDate && installDate <= installEndDate;
      });
      setSearchResultFound(filteredData.length > 0);
      setData(filteredData);
    }
  }, [installStartDate, installEndDate, payments.data, selectedDateOption]);


  const formatSelectedDate = (startDate, endDate, filterType) => {
    if (startDate && endDate) {
      return `${startDate.toLocaleDateString()} To ${endDate.toLocaleDateString()}`;
    } else {
      if (filterType === 'install') {
        return 'Install date between';
      } else if (filterType === 'expiry') {
        return 'Expiry date between';
      }
    }
  };


  const handleApplyDateFilter = () => {
    if (installStartDate && installEndDate) {
      const filteredData = payments.data.filter(item => {
        const installDate = new Date(item.appDetails[0].first_install);
        return installDate >= installStartDate && installDate <= installEndDate;
      });
      setSearchResultFound(filteredData.length > 0);
      setData(filteredData);
    }
    setIsDateDropdownOpen(false);
  };

  const handleClearDateFilter = () => {
    setInstallStartDate(null);
    setInstallEndDate(null);
    if (payments.data) {
      setSearchResultFound(true);
      setData(payments.data);
    }
    setIsDateDropdownOpen(false);
  };


  //Select Expiry Date Dropdown
  const toggleExpiryDateDropdown = () => {
    setIsExpiryDateDropdownOpen(prevState => !prevState);
  };


  useEffect(() => {
    if (expiryStartDate && expiryEndDate) {
      const filteredData = payments.data.filter(item => {
        const expiryDate = new Date(item.appDetails[0].expiry_date);
        return expiryDate >= expiryStartDate && expiryDate <= expiryEndDate;
      });
      setSearchResultFound(filteredData.length > 0);
      setData(filteredData);
    }
  }, [expiryStartDate, expiryEndDate, payments.data]);


  const handleApplyExpiryDateFilter = () => {
    if (expiryStartDate && expiryEndDate) {
      const filteredData = payments.data.filter(item => {
        const expiryDate = new Date(item.appDetails[0].expiry_date);
        return expiryDate >= expiryStartDate && expiryDate <= expiryEndDate;
      });
      setSearchResultFound(filteredData.length > 0);
      setData(filteredData);
      setIsExpiryDateDropdownOpen(false)
    }
    else {
      setSearchResultFound(true);
      setData(payments.data);
    }
  };


  const handleClearExpiryDateFilter = () => {
    setExpiryStartDate(null);
    setExpiryEndDate(null);
    if (payments.data) {
      setSearchResultFound(true);
      setData(payments.data);
    }
    setIsExpiryDateDropdownOpen(false)
  };


  const onCsvClick = () => {
    navigate('/csvData')
  }



  return (
    <div className='p-0 mx-4'>
      <Loader pageLoder={loader} />
      <ToastContainer />
      <div className=" uploadCSVbutton d-flex">
        <div className="custom-dropdown" ref={dropdownRef}>
          <div className={`dropdown ${isDropdownOpen ? 'show' : ''}`}>
            <button className="dropdown-toggle" type="button" onClick={toggleDropdown}>
              {selectedOption ? selectedOption : 'All Data'}
            </button>
            <ul className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
              <li><button className="dropdown-item" onClick={() => handleOptionSelect('All Data')}>All Data</button></li>
              <li><button className="dropdown-item" onClick={() => handleOptionSelect('Free Trial')}>Free Trial</button></li>
              <li><button className="dropdown-item" onClick={() => handleOptionSelect('Monthly')}>Monthly</button></li>
              <li><button className="dropdown-item" onClick={() => handleOptionSelect('Yearly')}>Yearly</button></li>
              <li><button className="dropdown-item" onClick={() => handleOptionSelect('Lifetime')}>Lifetime</button></li>
            </ul>
          </div>
        </div>

        <div className="custom-dropdown ms-1 " ref={dateDropdownRef}>
          <div className={`dropdown ${isDateDropdownOpen ? 'show' : ''}`}>
            <input
              className="dropdown-toggle"
              type="button"
              onClick={toggleDateDropdown}
              value={formatSelectedDate(installStartDate, installEndDate, 'install')}
            />
            <ul className={`dropdown-menu ${isDateDropdownOpen ? 'show' : ''}`}>
              <li>
                <div className="date-inputs" style={{ marginTop: '2px', display: 'flex' }}>
                  <DatePicker
                    wrapperClassName="datePicker ms-1 my-2"
                    selected={installStartDate}
                    onChange={date => setInstallStartDate(date)}
                    placeholderText="Start Date"
                  />
                  <DatePicker
                    wrapperClassName="datePicker ms-1 me-2  my-2"
                    selected={installEndDate}
                    onChange={date => setInstallEndDate(date)}
                    placeholderText="End Date"
                  />
                  <button className='mx-1 apply  my-2' onClick={handleApplyDateFilter}>Apply</button>
                  <button className='mx-1 clear my-2' onClick={handleClearDateFilter}>Clear</button>

                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="custom-dropdown ms-1 me-2" ref={dateDropdownRef}>
          <div className={`dropdown ${isExpiryDateDropdownOpen ? 'show' : ''}`}>
            <input
              className="dropdown-toggle"
              type="button"
              onClick={toggleExpiryDateDropdown}
              value={formatSelectedDate(expiryStartDate, expiryEndDate, 'expiry')}
            />
            <ul className={`dropdown-menu ${isExpiryDateDropdownOpen ? 'show' : ''}`}>
              <li>
                <div className="date-inputs" style={{ marginTop: '2px', display: 'flex' }}>
                  <DatePicker
                    wrapperClassName="datePicker ms-1 my-2"
                    selected={expiryStartDate}
                    onChange={date => setExpiryStartDate(date)}
                    placeholderText="Start Date"
                  />
                  <DatePicker
                    wrapperClassName="datePicker ms-1 me-2  my-2"
                    selected={expiryEndDate}
                    onChange={date => setExpiryEndDate(date)}
                    placeholderText="End Date"
                  />
                  <button className='mx-1 apply  my-2' onClick={handleApplyExpiryDateFilter}>Apply</button>
                  <button className='mx-1 clear my-2' onClick={handleClearExpiryDateFilter}>Clear</button>
                </div>
              </li>
            </ul>
          </div>
        </div>




        <div className='aploadDIv ms-auto' onClick={() => setShowModal(true)}>
          <div><MdCloudUpload className='aploadIcon' /></div>
          <div className='pt-1 ms-2' style={{ fontSize: 'small', userSelect: "none" }}>Upload CSV</div>
        </div>
        <button onClick={onCsvClick} className='ms-2 eyeButton' style={{ padding: '0px 10px', border: 'none', outline: 'none', background: '#007bff', borderRadius: '4px' }}>
          <FaRegEye style={{ color: "white", fontSize: '20px' }} />
        </button>
      </div>

      <ModalBox showModal={showModal} setShowModal={setShowModal} />


      <Card style={{ marginBottom: "8px" }}>
        <CardTitle>
          <div className='d-flex justify-content-between align-items-center search-div'>
            <div>
              <h5 style={{ margin: "", paddingTop: '5px' }}>Payment</h5>
            </div>
            <div className="d-flex align-items-center search-input">
              <input id="searchInput" type="text" style={{ marginRight: '5px' }} placeholder='Search...' />
              <FaSearch className='search-icon' />
            </div>
          </div>
        </CardTitle>
      </Card>
      <div style={{ maxHeight: `${windowHeight - 242}px`, overflowY: "auto" }}>
        {searchResultFound ? (
          <Table id="dataTable" bordered responsive hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Payment Type</th>
                <th>Device ID</th>
                <th>Email</th>
                <th>Install Date</th>
                <th>Payment Date</th>
                <th>Expiry Date</th>
                <th>Remaining Time</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems && currentItems.map((item, index) => {
                const paymentFlow = item.paymentFlows[0];
                const firstInstall = item.appDetails[0];
                const lifeSubscriptionEmail = item.lifeSubscription[0];

                const expiryDate = new Date(firstInstall.expiry_date);
                const currentDate = new Date();

                // Calculate remaining days
                let remainingDays;
                if (currentDate >= expiryDate) {
                  remainingDays = 0;
                } else {
                  remainingDays = Math.ceil((expiryDate - currentDate) / (1000 * 60 * 60 * 24));
                }

                // Calculate remaining years, months, and days
                const years = Math.floor(remainingDays / 365);
                const months = Math.floor((remainingDays % 365) / 30);
                const days = remainingDays % 30;

                const remainingTime = `${years > 0 ? years + " year" + (years > 1 ? "s" : "") : ""} ${months > 0 ? months + " month" + (months > 1 ? "s" : "") : ""} ${days > 0 ? days + " day" + (days > 1 ? "s" : "") : "0"}`;

                return (
                  <tr key={index} ref={editingIndex === index ? editRef : null}>
                    <th scope="row">{indexOfFirstItem + index + 1}</th>
                    <td>
                      {editingIndex === index ? (
                        <select value={editedPaymentType} onChange={(e) => setEditedPaymentType(e.target.value)}>
                          <option value="Free Trial">Free Trial</option>
                          <option value="Monthly">Monthly</option>
                          <option value="Yearly">Yearly</option>
                          <option value="Lifetime">Lifetime</option>
                        </select>
                      ) : (
                        paymentFlow.payment_type
                      )}
                    </td>
                    <td>{item.device_ID}</td>
                    <td>{lifeSubscriptionEmail?.email}</td>
                    <td>{firstInstall.first_install?.split('T')[0]}</td>

                    <td>
                      {paymentFlow.payment_date?.split('T')[0]}
                    </td>

                    <td>
                      {editingIndex === index ? (
                        <input type="date" value={editedExpiryDate} onChange={(e) => setEditedExpiryDate(e.target.value)} />
                      ) : (
                        firstInstall.expiry_date?.split('T')[0]
                      )}
                    </td>

                    <td>{remainingTime}</td>
                    <td>
                      {paymentFlow.payment_check && remainingDays !== 0 ? (
                        <button className="btn-active">Active</button>
                      ) :
                        paymentFlow.payment_check === true && remainingDays === 0 ? (
                          <button className="btn-expired">Expired</button>
                        ) : paymentFlow.payment_type === "Free Trial" && remainingDays !== 0 ? (
                          <button className="btn-trial">Trial</button>
                        ) : paymentFlow.payment_type === "Free Trial" && remainingDays === 0 ? (
                          <button className="btn-expired">Expired</button>
                        ) : paymentFlow.payment_type === "Monthly" && remainingDays === 0 ? (
                          <button className="btn-expired">Expired</button>
                        )
                          : paymentFlow.payment_type === "Yearly" && remainingDays === 0 ? (
                            <button className="btn-expired">Expired</button>
                          )
                            : (
                              <button className="btn-pending">Pending</button>
                            )}
                    </td>
                    <td>
                      {editingIndex === index ? (
                        <button className='ms-2' style={{ border: 'none', outline: 'none', background: '#105901', fontSize: '14px', color: "white", padding: "2px 9px", borderRadius: '5px' }} onClick={() => handleSaveClick(index)}>Save</button>
                      ) : (
                        <FiEdit className='ms-3' style={{ color: '#ff0000', cursor: 'pointer' }} onClick={() => handleEditClick(index)} />
                      )}
                    </td>
                  </tr>
                );
              })}

            </tbody>
          </Table>
        ) : (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>No Data found</div>
        )}


      </div>
      <Pagination>
        <Pagination.Prev
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {renderPageNumbers()}
        <Pagination.Next
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={indexOfLastItem >= data.length}
        />
      </Pagination>
    </div>
  );
};

export default Payments;
